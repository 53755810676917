import React from 'react';
import { useQuery } from 'react-apollo';

import { isUnauthorizedError } from '@confluence/error-boundary';
import { markErrorAsHandled } from '@confluence/graphql';
import { usePageState } from '@confluence/page-context';
import { generateSlug } from '@confluence/route-manager';

import { ContentRedirectComponent } from './ContentRedirectComponent';
import { ContentRedirectQuery } from './ContentRedirectQuery.graphql';
import type {
	ContentRedirectQuery as Q,
	ContentRedirectQueryVariables as V,
} from './__types__/ContentRedirectQuery';

export const ContentRedirect = ({ versionOverride }: ContentRedirectProps) => {
	const [{ contentId, contentSlug, spaceKey }] = usePageState();
	const { data, loading, error } = useQuery<Q, V>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		ContentRedirectQuery,
		{
			errorPolicy: 'all',
			variables: {
				contentId,
				versionOverride,
			},
			context: { single: true },
		},
	);

	if (loading) {
		return null;
	}

	// UnauthorizedError is a special case here since no specific UI are required for it.
	// In case of an UnauthorizedError the data or content are expected to be empty, and
	// ContentRedirectComponent will handle such case properly.
	if (isUnauthorizedError(error)) {
		markErrorAsHandled(error);
	}

	const content = data?.content?.nodes?.[0];
	const space = content?.space;
	const title = content?.title;

	const contentWithSlug = content && {
		...content,
		slug: generateSlug(title),
		spaceKey: space?.alias || space?.key,
		createdDate: content?.history?.createdDate,
	};

	return (
		<ContentRedirectComponent
			contentSlugInUrl={contentSlug}
			contentWithSlug={contentWithSlug}
			space={space}
			spaceKeyInUrl={spaceKey}
		/>
	);
};

export type ContentRedirectProps = Pick<V, 'versionOverride'>;
