import gql from 'graphql-tag';

export const ContentPrerequisitesQuery = gql`
	query ContentPrerequisitesQuery(
		$contentId: ID!
		$missingSpaceKey: Boolean = false
		$spaceKey: String
	) {
		content(id: $contentId) {
			nodes {
				id
				type
				space {
					id
					key
					alias
				}
			}
		}
		space(key: $spaceKey) @skip(if: $missingSpaceKey) {
			id
			name
		}
	}
`;
