import gql from 'graphql-tag';

export const ContentRedirectQuery = gql`
	query ContentRedirectQuery($contentId: ID, $versionOverride: Int) {
		content(id: $contentId, version: $versionOverride) {
			nodes {
				id
				title
				space {
					id
					homepageId
					key
					alias
				}
				history {
					createdDate
				}
			}
		}
	}
`;
